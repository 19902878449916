
window.addEventListener("load", (event) => {
    initSwiperQuote();
    initSwiperTeam();
    initSwiperAuto();
    initSwiperTeaser()
    accordion();
    popup();
    openSublistNav();
    scrollSlider();
    // scrollSliderAlt();
    colorHeader();
    initSwiperImageText() ;

});

window.addEventListener("resize", (event) => {

});





function openNav() {
    document.querySelector(".js-nav").classList.add("active");
    document.querySelector("body").classList.add("freeze");
    document.querySelector(".js-cta").style.display = "none";
}

function closeNav() {
    document.querySelector(".js-nav").classList.remove("active");
    document.querySelector("body").classList.remove("freeze");
    document.querySelector(".js-cta").style.display = "block";
}

function openSublistNav() {
    document.querySelector(".js-open-sublist").addEventListener("click", function () {
        this.closest("li").classList.toggle("open-sublist");
    })
}

/****************
 * Swiper Quote 
 ***************/
function initSwiperQuote() {

    const initSwiperQuote = new Swiper('.swiper-quote', {
        autoHeight: true,
        spaceBetween: 20,
        loop: true,
        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar',
        },
    });
}

/****************
 * Swiper Team 
 ***************/
function initSwiperTeam() {
    const swiperTeam = new Swiper('.swiper-team', {
        slidesPerView: 1.6,
        centeredSlides: true,
        spaceBetween: 45,
        loop: true,
        autoplay: {
            enabled: false,
            disableOnInteraction: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            // when window width is >= 768
            1200: {
                slidesPerView: 4,
                speed: 2000,
                loop: true,
                spaceBetween: 85,
                centeredSlides: false,
                autoplay: {
                    enabled: true,
                    delay: 1000,
                    disableOnInteraction: true
                }

            },
            768: {
                slidesPerView: 3,
                speed: 2000,
                loop: true,
                centeredSlides: false,
                autoplay: {
                    enabled: true,
                    delay: 1000,
                    disableOnInteraction: true
                }

            },
        },
    });

}

/****************
 * swiper Auto 
 ***************/
function initSwiperAuto() {
    const swiperAuto = new Swiper('.swiper-auto', {
        slidesPerView: 1.6,
        centeredSlides: true,
        loop: true,
        spaceBetween: 25,
        autoplay: {
            enabled: false,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            // when window width is >= 768
            1200: {
                slidesPerView: 4,
                speed: 2000,
                loop: true,
                centeredSlides: false,
                spaceBetween: 30,
                autoplay: {
                    enabled: true,
                    delay: 1000,
                    disableOnInteraction: true
                }

            },
            768: {
                slidesPerView: 3,
                speed: 2000,
                loop: true,
                centeredSlides: false,
                autoplay: {
                    enabled: true,
                    delay: 1000,
                    disableOnInteraction: true
                }

            },
        },
    });
}

/****************
 * Swiper Teaser 
 ***************/
function initSwiperTeaser() {
    const swiperTeaser = new Swiper('.swiper-teaser', {
        slidesPerView: 1,
        spaceBetween: 50,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
        },
    });
    var slider = document.querySelector(".swiper-teaser")
    // check if slider is on page
    if (slider && window.innerWidth > 1100) {
        var parent = document.querySelector(".js-button-pos");
        var height = document.querySelector(".js-get-buttton-bottom").offsetHeight;
        var magicNumber = 18;
        height = magicNumber + height;

        parent.querySelectorAll(".js-set-button-pos").forEach((elem) => {
            elem.style.bottom = height + "px";
        })

    }
}

/****************
 * swiper image-text 
 ***************/
function initSwiperImageText() {
    const swiperImageText = new Swiper('.swiper-image-text ', {
        slidesPerView: 1,
        centeredSlides: true,
        speed: 2000,
        loop: true,
        spaceBetween: 0,
        autoplay: {
            enabled: true,
            delay: 1000,
            disableOnInteraction: true
        },
    });
}


/****************
 * accordion 
 ***************/
function accordion() {
    var accordionHeaders = document.querySelectorAll(".js-accordion-header");

    accordionHeaders.forEach(function (accordionHeader) {
        accordionHeader.addEventListener("click", function () {

            var accordionItem = this.closest(".js-accordion-item");
            accordionItem.classList.toggle("active");

            var accordionBody = accordionItem.querySelector(".js-accordion-body");
            accordionBody.style.height = accordionBody.style.height === '' ? `${accordionBody.scrollHeight}px` : '';
        });
    });
    document.querySelectorAll(".js-accordion-item.active").forEach(function (item) {
        var accordionBody = item.querySelector(".js-accordion-body");
        accordionBody.style.height = `${accordionBody.scrollHeight}px`;
    })
}

/****************
 * popup 
 ***************/
function popup() {

    // Get the popup dialog element
    var popupDialog = document.querySelector(".js-popup");

    // Get the popup state from sessionStorage
    var popupState = sessionStorage.getItem('popupState');

    // Get the close button element
    var closePopupBtns = document.querySelectorAll(".js-close-popup");

    // Function to close the popup dialog
    function closePopup() {
        popupDialog.close();
        sessionStorage.setItem('popupState', true);
        popupDialog.classList.remove("active");
        document.body.classList.remove("freeze");
    }

    // Add click event listener to the close button
    closePopupBtns.forEach(function (btn) {
        btn.addEventListener("click", closePopup);
    })


    // Check if popupState is false and the popupDialog exists
    if (!popupState && popupDialog) {
        // Show the popup dialog after X milliseconds
        setTimeout(function () {
            popupDialog.showModal();
            popupDialog.classList.add("active");
            document.body.classList.add("freeze");
        }, 0);
    }
}

/****************
 * scrollslider 
 ***************/
function scrollSlider() {
    let scrollSlider = document.querySelector(".js-scroll-slider");
    if (scrollSlider !== null) {


        // set css backgroundimage from data attribute
        document.querySelectorAll(".js-scroll-slider-image").forEach(function (node) {
            const url = node.dataset.image;
            node.style.backgroundImage = `url(${url})`;
        })

        const images = gsap.utils.toArray(".js-scroll-slider-image");
        const texts = gsap.utils.toArray(".js-scroll-slider-text");

        const imagesTl = gsap.timeline({
            defaults: {
                ease: "none"
            },
            scrollTrigger: {
                trigger: ".js-scroll-slider",
                pin: true,
                start: `${window.innerWidth > 1000 ? "center center" : "top top"}`,
                end: `+=${(images.length - 1) * 100}%`,
                // markers: true,
                scrub: 1,
            }
        });

        images.forEach((image, i) => {
            const text = texts[i];
            imagesTl.to(image, { clipPath: "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)" })
                .to(text, { opacity: 1, ease: 'power4.out', pointerEvents: "auto" }, '<')
                .to(text, { opacity: `${i === images.length - 1 ? 1 : 0}`, ease: 'power1.in' })
        });
    }
}

/****************
 * Scroll top header
 ***************/
function colorHeader() {
    const firstContentHeight = document.querySelector(".js-find-first-content").children[0].getBoundingClientRect().height;
    window.addEventListener("scroll", (event) => {
        let scrollTop = window.scrollY;
        let header = document.querySelector(".js-header");

        if (scrollTop + 25 > firstContentHeight) {
            header.classList.add("scrollActive")
        }
        else {
            header.classList.remove("scrollActive")
        }
    });
}